import React from 'react'
import { Link, Heading, P } from '@klickmarketing/react-components'
import { Box } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'

import { ContentImageGrid, Note } from '../base'

const MaskGrid = (props) => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "foundation/masks.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 896, layout: CONSTRAINED)
        }
      }
    }
  `)
  return (
    <ContentImageGrid
      imageProps={{
        sources: data.file.childImageSharp.gatsbyImageData,
        alt: 'Photo of stacked boxes of masks',
      }}
      {...props}
    >
      <Heading variant="h4" font="primary">
        Providing PPE
      </Heading>
      <P variant="subtitle1">
        <strong>Answering the call for coverage.</strong>
      </P>
      <P>
        Connecting the dots in our network to respond to the urgent call for PPE
        from hospitals. Klick Health has donated over 400,000 respiratory face
        masks to the hospitals and healthcare systems across the Greater Toronto
        Area (GTA). This will significantly increase the number of new
        respiratory face masks being obtained for frontline healthcare
        professionals in&nbsp;Toronto.
      </P>
      <P>
        We are calling on other companies to step up and help ensure their
        city’s healthcare community and first responders are covered during this
        quickly escalating public health&nbsp;crisis.
      </P>
      <P>
        <Link
          type="text"
          href="https://www.klick.com/health/announcements/klick-health-donates-over-300k-masks-to-toronto-hospitals/"
        >
          Learn more about our donation to Toronto Hospitals.
        </Link>
      </P>
      <Box pt={3}>
        <Note>
          <strong>Partners & Advisors:</strong>
        </Note>
        <Note>Ontario Ministry of Health and Long-Term Care</Note>
      </Box>
    </ContentImageGrid>
  )
}

export default MaskGrid
