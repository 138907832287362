import React from 'react'
import { FadeIn, Heading, Section } from '@klickmarketing/react-components'
import { Container } from '@material-ui/core'

import IntubationBoxGrid from './IntubationBoxGrid'
import MaskGrid from './MaskGrid'

const Projects = () => {
  return (
    <Section id="projects">
      <Container maxWidth="md">
        <FadeIn from="bottom">
          <Heading variant="h2" align="center">
            Projects
          </Heading>
        </FadeIn>
      </Container>
      <MaskGrid id="masks" direction="row-reverse" />
      <IntubationBoxGrid id="intubation-box" alt />
    </Section>
  )
}

export default Projects
