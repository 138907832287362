import React from 'react'
import { Heading, NoWrap, P, Link } from '@klickmarketing/react-components'
import { graphql, useStaticQuery } from 'gatsby'

import { ContentImageGrid } from '../base'

const IntubationBoxGrid = (props) => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "intubation-box.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 896, layout: CONSTRAINED)
        }
      }
    }
  `)
  return (
    <ContentImageGrid
      imageProps={{
        sources: data.file.childImageSharp.gatsbyImageData,
        alt: 'Photo of doctors using the intubation box',
      }}
      {...props}
    >
      <Heading variant="h4" font="primary">
        <NoWrap>COVID-19</NoWrap> Intubation Box
      </Heading>
      <P variant="subtitle1">
        <strong>Protecting healthcare practitioners.</strong>
      </P>
      <P>
        A simple piece of protective equipment that could make a difference in
        the fight against the outbreak.{' '}
        <Link
          type="text"
          href="https://www.nejm.org/doi/full/10.1056/NEJMc2007589"
        >
          The New England Journal of Medicine
        </Link>{' '}
        has just published a video showing the effect of the intubation box.
      </P>
      <P>
        In partnership with{' '}
        <Link type="text" href="https://www.klick.com/">
          Klick Health
        </Link>{' '}
        and{' '}
        <Link type="text" href="https://italicpress.com/">
          Italic Press
        </Link>
        , rapid localization (adjusting dimensions to match North American
        hospital beds and body sizes), prototyping, and distribution to Toronto
        area hospitals began within days. It is our hope to provide protection
        to physicians intubating patients across the Greater Toronto Area and
        North Eastern United States.
      </P>
      <P>
        <Link type="text" href="/projects/box">
          Learn more about the intubation box project.
        </Link>
      </P>
    </ContentImageGrid>
  )
}

export default IntubationBoxGrid
