import React from 'react'
import {
  Heading,
  KlickLogo,
  match,
  NoWrap,
  P,
  ThemeProvider,
} from '@klickmarketing/react-components'
import { Box, Container } from '@material-ui/core'
import styled from 'styled-components'

import Hero from './Hero'

const HeroHome = (props) => {
  return (
    <ThemeProvider themeType="onBlack">
      <Hero publicId="v1586466271/covid-19/videos/covid19-banner" pt={0} pb={0}>
        <Box py={5}>
          <Box pb={5} textAlign="center">
            <Logo themeType="onPrimary" />
          </Box>
          <StyledHeading variant="h1" align="center">
            Frontlines.Health
          </StyledHeading>
          <Container maxWidth="sm">
            <P variant="h6" align="center" paragraph={false}>
              Frontlines.Health Foundation was created by Klick Health to
              support frontline and allied healthcare workers during the{' '}
              <NoWrap>COVID-19</NoWrap>&nbsp;pandemic.
            </P>
          </Container>
        </Box>
      </Hero>
    </ThemeProvider>
  )
}

const StyledHeading = styled(Heading)`
  ${match.isXS} {
    font-size: ${({ theme }) => theme.typography.pxToRem(30)};
  }
`

const Logo = styled(KlickLogo)`
  height: 90px;
  color: #fff;
  display: inline-block;
`

export default HeroHome
