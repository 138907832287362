import React from 'react'
import {
  Button,
  Link,
  FadeIn,
  Heading,
  Section,
} from '@klickmarketing/react-components'
import { Box, Container } from '@material-ui/core'

const DonateBar = () => {
  return (
    <Section id="donate" textAlign="center" pt={0}>
      <FadeIn from="bottom">
        <Container maxWidth="xs">
          <Heading variant="h4" font="primary">
            Save a frontline worker’s life with vital protective equipment.
          </Heading>
          <Box pt={3}>
            <Button
              component={Link}
              trackId="donate_bar"
              role="link"
              size="xxlarge"
              href="https://torontofoundation.ca/frontline-health-foundation"
              target="_blank"
              rel="noopener noreferrer"
            >
              Donate
            </Button>
          </Box>
        </Container>
      </FadeIn>
    </Section>
  )
}

export default DonateBar
