import React from 'react'
import { Container } from '@material-ui/core'

import socialThumb from '../../assets/images/social-thumb-frontlines.jpg'
import { Hr, NavBar } from '../../components/base'
import DonateBar from '../../components/DonateBar/DonateBar'
import HeroFrontlines from '../../components/Hero/HeroFrontlines'
import Layout from '../../components/Layout/Layout'
import Mission from '../../components/Mission/Mission'
import Projects from '../../components/Projects/Projects'

const myRoute = '/frontlines'
const navItems = [
  { hash: 'mission', label: 'Mission', to: `${myRoute}#mission` },
  { hash: 'projects', label: 'Projects', to: `${myRoute}#projects` },
]

const IndexPage = () => {
  return (
    <Layout
      seoProps={{
        title: 'Frontlines.Health | A COVID-19 Foundation',
        titleTemplate: `%s`,
        description:
          'The demand for protective equipment - intubation boxes, masks, and other forms of personal protective equipment (PPE) - on the frontlines is great. Help us help healthcare workers. Donate now.',
        socialThumb: { socialThumb },
      }}
    >
      <HeroFrontlines />
      <NavBar items={navItems} />
      <Mission />
      <ContainedHr />
      <Projects />
      <DonateBar />
    </Layout>
  )
}

const ContainedHr = () => (
  <Container maxWidth="lg">
    <Hr />
  </Container>
)

export default IndexPage
