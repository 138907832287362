import React from 'react'
import {
  FadeIn,
  Heading,
  match,
  P,
  Section,
} from '@klickmarketing/react-components'
import { Box, Container, Grid, useMediaQuery } from '@material-ui/core'
import styled from 'styled-components'

import breakpoint from '../../common/breakpoint'
import { SectionItem } from '../base'

import logoMichaelGarronHospital from './images/logo-michael-garron-hospital.jpg'
import logoMountSanaiHospital from './images/logo-mount-sanai-hospital.jpg'
import logoTrilliumHealthPartners from './images/logo-trillium-health-partners.jpg'
import logoUHN from './images/logo-uhn.jpg'

const Mission = () => {
  const isMobile = useMediaQuery(match.isXS)
  const gridSpacing = isMobile ? 3 : 6
  return (
    <Section id="mission">
      <SectionItem pt={0} pb={0} align="center">
        <FadeIn from="bottom">
          <Container maxWidth="sm">
            <Heading variant="h2">Mission</Heading>
            <P>
              The foundation’s efforts are laser-focused on ensuring the health
              and safety of the people keeping us all safe and well.
            </P>
            <P>
              The demand for protective equipment on the frontlines is greater
              than Klick can serve on our own. One hundred percent of donations
              to Frontlines.Health will go towards raw material and fabrication
              costs of intubation boxes and other critical PPE as Klick will
              continue to cover all project design, project management, and
              partner coordination costs.
            </P>
            <P paragraph={false}>
              We’re proud to support a number of healthcare facilities,
              including:{' '}
            </P>
          </Container>
        </FadeIn>
        <FadeIn from="bottom">
          <Container maxWidth="lg">
            <LogoContainer pt={4}>
              <Grid
                container
                spacing={gridSpacing}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Logo
                    src={logoMichaelGarronHospital}
                    alt="logo for michael garron hospital"
                  />
                </Grid>
                <Grid item>
                  <Logo
                    src={logoMountSanaiHospital}
                    alt="logo for Mount Sanai Hospital"
                  />
                </Grid>
                <Grid item>
                  <Logo
                    src={logoTrilliumHealthPartners}
                    alt="logo for Trillium Health Partners"
                  />
                </Grid>
                <Grid item>
                  <Logo
                    src={logoUHN}
                    alt="logo for University Health Network"
                  />
                </Grid>
              </Grid>
            </LogoContainer>
          </Container>
        </FadeIn>
      </SectionItem>
    </Section>
  )
}

const LogoContainer = styled(Box)`
  max-width: 288px;

  ${breakpoint.sm`
    max-width: 610px;
  `}

  ${breakpoint.lg`
    max-width: none;
  `};
`

const Logo = styled.img`
  max-width: 132px;

  ${breakpoint.sm`
    max-width: 310px;
  `}

  ${breakpoint.md`
    max-width: none;
  `}
`

export default Mission
